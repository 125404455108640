<template lang="pug">
    v-container.text-center
        img(src="../assets/error/404.jpg" alt="404" width="30%")
</template>
<script>
export default {
  mounted() {
    this.$router.push({ name: "Home" });
  }
};
</script>
